import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import Flags from "country-flag-icons/react/3x2";
import ClickOutside from "../clickOutside";
import SlideMenu from "./slideMenu";
import { Auth0Service } from "../../../services";
import { sessionManager } from "../../../managers/sessionManager";
import { browseStoriesAndUrl } from "../../../constants";
import LanguageDropdown from "./languageDropdown";
import countriesList from "../../../constants/countriesList";

const Header = ({ top = "top-0", showCreateNew = false }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const [showBrowseStories, setShowBrowseStories] = useState(false);
  const [showLanguageMenu, setShowLanguageMenu] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(getInitialLanguage());

  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const toggleMenu = () => {
    setShowLanguageMenu((prevShowLanguageMenu) => !prevShowLanguageMenu);
  };

  const onLogout = () => {
    dispatch({ type: "REMOVE_USER" });
    new Auth0Service().logOut();
    sessionManager.removeDataFromCookies("story_config");
  };

  const getProfileUrl = () => {
    const lastEight = user?._id?.substring(user?._id?.length - 8);
    return user?.firstName?.toLowerCase() + "-" + lastEight;
  };

  const onLangChange = ({ code, ...rest }) => {
    const select = document.querySelector(".goog-te-combo");
    if (select) {
      const option = select.querySelector(`option[value=${code}]`);
      option.selected = true;
      setSelectLanguage({ code, ...rest });
      setShowLanguageMenu(false);
      if ("createEvent" in document) {
        const event = document.createEvent("HTMLEvents");
        event.initEvent("change", false, true);
        select.dispatchEvent(event);
        select.dispatchEvent(event);
      } else {
        select.fireEvent("onchange");
      }
    }
  };

  useEffect(() => {
    setSelectLanguage(getInitialLanguage());
  }, []);

  function getInitialLanguage() {
    const googleTrans = sessionManager.getDataFromCookies("googtrans");
    const currentLanguage = googleTrans ? googleTrans.split("/")[2] : "en";
    const country = countriesList.find((i) => i?.code === currentLanguage) || {};

    return {
      code: currentLanguage,
      countryCode: country?.countryCode || "",
    };
  }

  return (
    <nav className={`border-b border-black-20 bg-black-0 sticky left-0 z-50 ${top}`}>
      <div className="mx-auto flex justify-between md:h-87px h-16 items-center px-4">
        <div>
          <Link to="/" className="md:text-ft36-50 text-ft25-37 font-LobsterRegular text-white">
            {user.subscriptionPlan.planDetails.planType === "Paid" ? (
              <img className="md:w-auto w-30" src="/images/makemytale-logo.png" alt="" />
            ) : (
              <img className="md:w-auto w-30" src="/images/MakeMyTale-logo-freeuser.png" alt="" />
            )}
          </Link>
        </div>
        <div className="relative mr-auto pl-12 2md:flex hidden font-PoppinsMedium text-ft17-26">
          <button
            onClick={() => setShowBrowseStories((prevShowBrowseStories) => !prevShowBrowseStories)}
            className="text-white flex"
          >
            Browse Stories
            <span className="pl-2  mt-2.5 rotate-180">
              <img
                className={`text-white transform duration-100 ${
                  showBrowseStories && "rotate-180"
                } `}
                src="/images/down-arrow.svg"
                alt=""
              />
            </span>
          </button>
          {showBrowseStories && <BrowseStoryDropdown onClickOutside={() => setShowBrowseStories(false)} />}
          <div className="pl-12">
            <Link to="/subscription-plan" className="text-white font-PoppinsRegular ">
              Pricing
            </Link>
          </div>
          <div className="pl-12 flex">
            <Link to="/story-book/home" className="text-white font-PoppinsRegular ">
              Make a Book
            </Link>
            <span className="">
              <img className="ml-2.5 mt-1" src="/images/newtag-icon.svg" alt="" />
            </span>
          </div>
        </div>
        <div className="flex md:gap-6 gap-2 md:ml-0 -ml-10 items-center justify-center font-PoppinsMedium md:text-ft12-18 text-white">
          <div className="relative">
            <button
              onClick={toggleMenu}
              translate="no"
              className="text-white min-w-22 flex gap-1 items-center justify-center md:ml-auto ml-1"
            >
              <Flag
                className="md:w-7 w-3"
                icon={Flags[selectLanguage?.countryCode]}
              />
              <span className="md:text-ft15-21 mobile:text-ft12-18">
                {selectLanguage?.code?.toUpperCase()}
              </span>
              <span className="rotate-180">
                <img
                  className={`text-white transform duration-100 ${
                    showLanguageMenu && "rotate-180"
                  } `}
                  src="/images/down-arrow.svg"
                  alt=""
                />
              </span>
            </button>
            {showLanguageMenu && (
              <LanguageDropdown
                onClickOutside={() => setShowLanguageMenu(false)}
                onLangChange={onLangChange}
                value={selectLanguage}
              />
            )}
          </div>
          {showCreateNew && (
            <div className="sm:flex md:gap-6 gap-3.5 hidden">
              <Link
                to="/"
                className="flex md:min-w-45 min-w-22 md:h-10 h-8.5 bg-red-20 hover:bg-red-30 md:rounded-5.5 rounded-full text-white items-center justify-center gap-2 font-PoppinsMedium text-ft16-22"
              >
                <img
                  className="hidden md:block md:h-auto h-4 w-4"
                  src="/images/pen-icon.svg"
                  alt=""
                />
                <span className="md:text-ft16-22 text-ft11-18">Create Story</span>
              </Link>
            </div>
          )}
          {user.isLoggedIn ? (
            <div className="relative grid place-content-center">
              <button onClick={() => setShowMenu((prevShowMenu) => !prevShowMenu)}>
                <img
                  className="md:h-10 md:w-10 w-7 h-7 rounded-full"
                  src={user.profilePicture}
                  alt={user.name}
                />
              </button>
              {showMenu && (
                <ProfileDropDown
                  userId={getProfileUrl()}
                  showResetPwd={user?.userId && user?.userId?.startsWith("auth")}
                  onClickOutside={() => setShowMenu(false)}
                  onLogout={onLogout}
                  planDetails={user.subscriptionPlan.planDetails}
                />
              )}
            </div>
          ) : (
            <Link
              className="flex md:w-27 md:h-10  min-w-22 h-8.5 bg-white hover:bg-red-30   text-red-20 hover:text-white md:rounded-5.5 rounded-full items-center justify-center gap-2 font-PoppinsMedium text-ft16-22"
              to="/login"
            >
              <span className="md:inline  font-PoppinsMedium md:text-ft16-22 text-ft11-18 ">
                Sign In
              </span>
            </Link>
          )}
          <button
            onClick={() => setShowSlideMenu((prevShowSlideMenu) => !prevShowSlideMenu)}
            className="cursor-pointer 2md:hidden"
          >
            <AiOutlineMenu className="w-7 h-7 text-white" />
          </button>
        </div>
      </div>
      <SlideMenu
        open={showSlideMenu}
        onClose={() => setShowSlideMenu(false)}
        subscriptionPlan={user.subscriptionPlan}
        planDetails={user.subscriptionPlan.planDetails}
      />
    </nav>
  );
};

const BrowseStoryDropdown = ({ onClickOutside }) => {
  return (
    <ClickOutside
      onClickOutside={onClickOutside}
      className="absolute top-11 bg-white borderRadius-3 text-black-10 rounded-md font-PoppinsRegular  w-45 z-10 text-ft17-26 pl-5 pt-2  pb-4 text-left font-semibold"
    >
      {browseStoriesAndUrl.map(({ to, name }, idx) => (
        <Link
          onClick={() => onClickOutside(false)}
          key={idx}
          to={to}
          className="block text-left mt-3"
        >
          {name}
        </Link>
      ))}
    </ClickOutside>
  );
};

const Flag = ({ icon: Icon }) => (!!Icon ? <Icon className="w-8 h-8" /> : null);

const ProfileDropDown = ({
  onLogout,
  onClickOutside,
  userId,
  showResetPwd = false,
  planDetails,
}) => {
  return (
    <ClickOutside
      onClickOutside={onClickOutside}
      className="absolute top-14 bg-black-10 border border-darkGray-10 right-0 w-55 z-10 text-ft17-26 pl-8 pt-15px pb-8"
    >
      <Link to={`/profile/${userId}`} className="block text-left mt-3">
        Profile
      </Link>
      <Link to="/billing" className="block text-left mt-3">
        Billing
      </Link>
      {planDetails?.planType === "Paid" && (
        <Link to="/story-book/my-books" className="block text-left mt-3">
          My Books
        </Link>
      )}
      <Link to="/my-bookmark" className="block text-left mt-3">
        My Bookmark
      </Link>
      <Link to="/created-stories" className="block text-left mt-3">
        My Created Stories
      </Link>
      <Link to="/read-stories" className="block text-left mt-3">
        My Read Stories
      </Link>
      {showResetPwd && (
        <Link to="/change-password" className="block text-left mt-3">
          Change Password
        </Link>
      )}
      <button onClick={onLogout} className="block text-left mt-3">
        Log Out
      </button>
    </ClickOutside>
  );
};

export default Header;
