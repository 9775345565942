import React, {useEffect} from "react";
import moment from "moment";
import Utils from "../../utility";
import { Link } from "react-router-dom";

function BillingComponent({
  currentPlan,
  subscribedPlan,
  onCancelSubscription,
}) {
  useEffect(() => {
    window.scrollTo(0,0)
  }, []);
  return (
    <>
      <div className="max-w-310  md:px-6 lg:px-4 px-8 mt-11  mx-auto">
        <div className=" text-white font-TimesBold text-ft30-32 pb-15">
          Billing
        </div>
        <div className=" text-gray-90 font-PoppinsSemiBold text-ft20-37">
          Active Plan
        </div>
        <div className=" text-white font-PoppinsRegular pt-3.5 text-ft20-37">
          {subscribedPlan?.planDetails?.planType?.toUpperCase()} PLAN{" "}
          <span className="text-ft16-22">
            (Active since{" "}
            {moment(subscribedPlan?.planDetails?.subscribedAt).format(
              "MMMM D, YYYY"
            )}
            )
          </span>
        </div>
        {subscribedPlan?.planDetails?.planType?.toLowerCase() !== 2 && (
          <div className="flex justify-end mb-10">
            <Link
              to="/subscription-plan"
              className="bg-red-10 py-2 px-10 rounded-5.5 text-white font-PoppinsMedium"
            >
              Upgrade plan
            </Link>
          </div>
        )}
        <div className={`h-px bg-gray-80  mt-11 mb-8`}></div>
        <div className="">
          <div className=" text-gray-90 font-PoppinsSemiBold pb-4 text-ft20-37">
            Your plan includes
          </div>
          <div>
            {currentPlan?.features.map((item, idx) => (
              <div
                key={idx}
                className="text-left flex items-center mx-2 font-PoppinsRegular  text-ft16-22 md:my-3 my-1"
              >
                <img
                  className="mr-3 mb-1 h-3.5 w-19px"
                  src="/images/tick-icon.svg"
                  alt=""
                />

                <div className="text-white  text-ft16-22 font-PoppinsRegular">
                  {item?.name}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`h-px bg-gray-80  mt-11 mb-8`}></div>
        <div className=" text-gray-90 font-PoppinsSemiBold text-ft20-37">
          Next Billing Date
        </div>
        <div className=" text-white font-PoppinsRegular pt-3.5 text-ft16-22">
          {moment(subscribedPlan?.planDetails?.nextBillingDate).format(
            "MMMM D, YYYY"
          )}{" "}
          for {Utils.formatCurrency(currentPlan?.price, currentPlan?.currency)}
        </div>
        <div className={`h-px bg-gray-80  mt-11 mb-8`}></div>
        {subscribedPlan?.planDetails?.planType?.toLowerCase() === "paid" && (
          <>
            <div className="flex justify-between">
              <div className=" text-gray-90 font-PoppinsSemiBold text-ft20-37">
                Payment Method
              </div>
              {subscribedPlan?.planDetails?.paymentLink && (
                <a
                  href={subscribedPlan.planDetails.paymentLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-red-10 font-PoppinsRegular text-ft16-22"
                >
                  Update Card
                </a>
              )}
            </div>
            <div className=" text-white font-PoppinsSemiBold pt-3.5 text-ft16-22 capitalize">
              {subscribedPlan?.planDetails?.paymentMethod}
            </div>
            <div className="flex justify-end pt-18.5 pb-20">
              <button
                onClick={onCancelSubscription}
                className="bg-red-10 py-2 px-10 rounded-5.5 text-white font-PoppinsMedium"
              >
                Stop Subscription
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default BillingComponent;
