import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux";
import { previewBook } from "../../../constants";
import { history } from "../../../managers/history";
import { sessionManager } from "../../../managers/sessionManager";
import Utils from "../../../utility";

const MainSection = () => {
  const user = useSelector((state) => state.user);
  const { subscriptionPlan } = user;
  const { planDetails } = subscriptionPlan;

  const onCreateBook = () => {
    if (!user.isLoggedIn) {
      history.push("/login");
      sessionManager.setDataInCookies("REDIRECT_LINK", "/story-book/home", 1);
      return;
    }
    if (planDetails?.planType?.toLowerCase() === "free") {
      Utils.failureToast("Subscribe to our premium plan to create books.", {
        style: { maxWidth: "500px", padding: "14px" },
      });
      setTimeout(() => {
        history.push("/subscription-plan");
      }, 2000);
      return;
    }
    if (subscriptionPlan.isLoading) return;
    if (planDetails?.planType?.toLowerCase() === "paid") {
      history.push("/story-book/create-book");
      return;
    }
    history.push("/subscription-plan");
  };

  return (
    <div className="text-white md:flex max-w-310 mx-auto justify-between pt-13.5 pb-15 px-4 md:px-6 lg:px-4">
      <div className="lg:max-w-155 md:max-w-104 sm:max-w-87.5 md:mx-0 mx-auto">
        <h2 className="font-TimesBold md:text-ft32-50 text-ft36-50">
          Create a Personalized Storybook
        </h2>
        <div className="md:hidden md:mt-20 mt-5">
          <img
            className="object-contain max-w-70 mx-auto"
            src={previewBook.imageUrl}
            alt=""
          />
        </div>
        <div className="md:hidden items-center gap-3.5 mt-6 flex flex-col">
          <button
            onClick={onCreateBook}
            className="bg-red-20 rounded-full md:w-50 min-w-79 w-full h-10 grid place-items-center font-PoppinsMedium text-ft17-26"
          >
            Create your Book
          </button>
          <a
            href={previewBook.pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="border border-white rounded-full  min-w-79 w-full h-10 grid place-items-center font-PoppinsMedium text-ft17-26"
          >
            Book Preview
          </a>
        </div>

        <p className="font-PoppinsRegular text-ft16-32 md:mt-2 mt-10">
          Create a cherished keepsake for your child with MakeMyTale's new
          Storybook feature. Relive the magic of your child's favorite
          AI-generated stories by turning them into a personalized book
          featuring their name, photo, and a custom author name .
        </p>
        <ul className="mt-10 flex flex-col gap-3.5">
          <li className="flex items-center gap-3.5">
            <div className="text-white bg-red-10 flex-shrink-0 rounded-full w-13 h-13 grid place-content-center">
              <img className="" src="/images/book-icon.svg" alt="" />
            </div>
            <p className="font-PoppinsMedium text-ft16-32">
              Combine up to 4 previously created stories into a single book.
            </p>
          </li>
          <li className="flex items-center gap-3.5">
            <div className="text-white bg-red-10 flex-shrink-0 rounded-full w-13 h-13 grid place-content-center">
              <img className="" src="/images/edit-icon.svg" alt="" />
            </div>
            <p className="font-PoppinsMedium text-ft16-32">
              Personalize the title and author name with your child's name.
            </p>
          </li>
          <li className="flex items-center gap-3.5">
            <div className="text-white bg-red-10 flex-shrink-0 rounded-full w-13 h-13 grid place-content-center">
              <AiOutlineUser className="w-7 h-7" />
            </div>
            <p className="font-PoppinsMedium text-ft16-32">
              Add your child's photo to the storybook.
            </p>
          </li>
          <li className="flex items-center gap-3.5">
            <div className="text-white bg-red-10 flex-shrink-0 rounded-full w-13 h-13 grid place-content-center">
              <img className="" src="/images/share-icon.svg" alt="" />
            </div>
            <p className="font-PoppinsMedium text-ft16-32">
              Easily download and share the storybook with friends and family.
            </p>
          </li>
        </ul>
        <div className="md:flex items-center gap-7 md:mt-13.5 mt-9.5 hidden">
          <button
            onClick={onCreateBook}
            className="bg-red-20 rounded-full md:w-50 min-w-79 w-full h-10 grid place-items-center font-PoppinsMedium text-ft17-26"
          >
            Create your Book
          </button>
          <a
            href={previewBook.pdfUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="border border-white rounded-full w-50 min-w-79 h-10 grid place-items-center font-PoppinsMedium text-ft17-26"
          >
            Book Preview
          </a>
        </div>
      </div>
      <div className="md:block hidden">
        <img
          className="ipad:max-w-83 md:max-w-75 lg:max-w-104 md:mt-3.5 mobile:mt-8"
          src={previewBook.imageUrl}
          alt=""
        />
      </div>
    </div>
  );
};

export default MainSection;
