import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { MdOutlineClose, MdKeyboardArrowDown } from "react-icons/md";
import { browseStoriesAndUrl } from "../../../constants";

const SlideMenu = ({ open, onClose }) => {
  const [showBrowse, setShowBrowse] = useState(false);

  useEffect(() => {
    document.body.style.overflow = open ? "hidden" : "";
  }, [open]);

  return (
    <div
      className={`w-full flex flex-col 2md:hidden top-0 bg-white fixed min-h-screen duration-150 z-50 ${
        open ? "right-0" : "-right-full"
      }`}
    >
      <div className="flex justify-between px-5 pt-4">
        <Link
          to="/"
          className="md:text-ft36-50 text-ft25-37 font-LobsterRegular text-black"
        >
          MakeMy<span className="text-red-10">Tale</span>
        </Link>
        <button onClick={() => onClose(false)}>
          <MdOutlineClose className="w-7 h-7 text-gray-10" />
        </button>
      </div>
      <div className="mt-15 px-7 font-PoppinsMedium text-ft17-26">
        <div
          onClick={() => setShowBrowse((p) => !p)}
          className="cursor-pointer flex items-center select-none "
        >
          <span>Browse Stories</span>
          <MdKeyboardArrowDown
            className={`w-7 h-7 ml-auto transform duration-100 ${
              showBrowse && "-rotate-180"
            }`}
          />
        </div>
        {showBrowse && (
          <div className="text-gray-120 font-PoppinsRegular">
            {browseStoriesAndUrl.map(({ to, name }, idx) => (
              <Link
                key={idx}
                to={to}
                onClick={() => onClose(false)}
                className="block text-left mt-3"
              >
                {name}
              </Link>
            ))}
          </div>
        )}
        <Link to="/subscription-plan" className="block mt-8">
          Pricing
        </Link>
        <div className="mt-8 flex">
          <Link to="/story-book/home" className="">
            Make a Book
          </Link>
          <span className="">
            <img className="ml-2.5 mt-1" src="/images/newtag-icon.svg" alt="" />
          </span>
        </div>
        <Link to="/about" className="block mt-8">
          About Us
        </Link>
        <Link to="/feedback" className="block mt-8">
          Feedback
        </Link>
      </div>
    </div>
  );
};

export default SlideMenu;
